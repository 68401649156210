import { Button, Flex } from "@chakra-ui/react";
import { Wheel } from "react-custom-roulette";

import { useFormClient } from "../hooks/formClient";

interface RoletteProps {
  bonuses: {
    name: string;
    id: string;
  }[];
  prizeNumber: number;
  mustSpin: boolean;
  loading: boolean;
  handleStopSpinning: () => void;
  handleSpinWheel: () => void;
}

export function Roulette({
  bonuses,
  prizeNumber,
  mustSpin,
  loading,
  handleStopSpinning,
  handleSpinWheel,
}: RoletteProps) {
  const { company } = useFormClient();
  return (
    bonuses.length > 0 && (
      <Flex
        flexDir={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={bonuses.map((bonus) => {
            const capitalize = (str: string) =>
              str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            return {
              option: capitalize(bonus.name),
              style: { fontSize: bonus.name.length > 16 ? 10 : 14 },
            };
          })}
          backgroundColors={[company.color, company.secondary_color]}
          outerBorderWidth={0}
          radiusLineWidth={1}
          textColors={["#ffffff"]}
          onStopSpinning={handleStopSpinning}
        />
        <Button
          position={"absolute"}
          zIndex={1000}
          rounded={"full"}
          width={20}
          height={20}
          onClick={handleSpinWheel}
          shadow={"md"}
          isLoading={loading}
          disabled={mustSpin ?? loading}
        >
          {mustSpin ? "Girando" : "Girar"}
        </Button>
      </Flex>
    )
  );
}
